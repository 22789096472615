



































































































import Vue from 'vue';

export default Vue.extend({
  layout: ['layout-page'],
  auth: true,
  data: () => ({
    isUserVerified: false,
    instructionUrl: undefined as string | undefined,
  }),
  computed: {
    isInstructionUrlSet(): boolean {
      if (this.instructionUrl === undefined || this.instructionUrl === null || this.instructionUrl === '') {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.isUserVerified = this.$service.api.users.getHasAccessToPrivateContent();
    this.instructionUrl = this.$config.values['learnit-instructions-url'];
  },
  methods: {
    openInstruction() {
      window.open(this.instructionUrl);
    },
  },
});
